import React from "react";
import "./project-section.css";
import Project from "./project/project";
import { Link, StaticQuery } from "gatsby";

export default props => (
  <section className="section_all bg-light" id="project">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section_title_all text-center">
            <div className="section_icons">
              <i className="mdi mdi-archive"></i>
            </div>
            <h3 className="mt-3">
              Our <span className="text_custom">Projects</span>
            </h3>
            <p className="section_subtitle mx-auto text-muted">
              {props.projectBlurb}
              {/* All icons were sourced from The Noun Project */}
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <StaticQuery
          query={graphql`
            {
              allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "project-name" } } }
              ) {
                nodes {
                  frontmatter {
                    image {
                      childImageSharp {
                        fluid(maxWidth: 150, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    title
                    projectname
                    projectdescription
                  }
                }
              }
            }
          `}
          render={data => {
            return data.allMarkdownRemark.nodes.map((val, index) => {
              // console.log(val);
              return (
                <Project
                  imgSrc={
                    val.frontmatter.image
                      ? val.frontmatter.image.childImageSharp.fluid
                      : ""
                  }
                  projectTitle={val.frontmatter.title}
                  projectName={val.frontmatter.projectname}
                  projectDescription={val.frontmatter.projectdescription}
                  key={index}
                />
              );
            });
          }}
        ></StaticQuery>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="section_title_all text-center">
            <Link className="btn btn_custom" to="/projects">
              More Projects
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);
