import React from 'react';
// import "../partner-section.css";

export default (props) => (
  <div className="col-lg-4">
    <div className="landing_client_box mx-auto text-center mt-3">
      <img
        src={props.imgSrc.src}
        alt=""
        className="img-fluid mx-auto d-block"
      />
      <div className="landing_testi_desc mt-4">
        <p className="user-work mb-0">{props.partnerName}</p>
        <p className="text-muted">{props.partnerDescription}</p>
      </div>
    </div>
  </div>
);
