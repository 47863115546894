import React from "react";
// import "./partner-section.css";
import Partner from "./partner/partner";
import { Link, StaticQuery } from "gatsby";

export default props => (
  <section className="section_all bg-light" id="partners">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section_title_all text-center">
            <div className="section_icons">
              <i className="mdi mdi-archive"></i>
            </div>
            <h3 className="mt-3">
              Our <span className="text_custom">Partners</span>
            </h3>
            <p className="section_subtitle mx-auto text-muted">
              {props.partnerBlurb}
              {/* All icons were sourced from The Noun Project */}
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <StaticQuery
          query={graphql`
            {
              allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "partner-name" } } }
              ) {
                nodes {
                  frontmatter {
                    image {
                      childImageSharp {
                        fluid(maxWidth: 150, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    title
                    partnername
                    partnerdescription
                  }
                }
              }
            }
          `}
          render={data => {
            return data.allMarkdownRemark.nodes.map((val, index) => {
              return (
                <Partner
                  imgSrc={
                    val.frontmatter.image
                      ? val.frontmatter.image.childImageSharp.fluid
                      : ""
                  }
                  partnerName={val.frontmatter.partnername}
                  partnerDescription={val.frontmatter.partnerdescription}
                  key={index}
                />
              );
            });
          }}
        ></StaticQuery>
      </div>
    </div>
  </section>
);
