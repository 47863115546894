import React from 'react';
import '../whatWeDo-section.css';

//props.serviceName props.serviceDescription props.iconName
export default (props) => {
  return (
    <div className="col-lg-4">
      <div className="services_box p-3 mt-3">
        <div className="service_icon">
          <img
            src={props.imgSrc.src}
            alt=""
            className="img-fluid d-block mx-auto"
          />
        </div>
        <div className="service_content mt-3">
          <h5 className="">{props.offeringname}</h5>
          <div className="service_devider mt-3 "></div>
          <p className="mt-3 text-muted mb-0">{props.offeringdescription}</p>
        </div>
      </div>
    </div>
  );
};
