import React from 'react';
import './whatWeDo-section.css';
import Offering from './offerings/offering';
import { Link, StaticQuery } from 'gatsby';

export default (props) => {
  return (
    <section className="section_all bg-light" id="offerings">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <div className="section_icons">
                <i className="mdi mdi-archive"></i>
              </div>
              <h3 className="mt-3">
                What We <span className="text_custom">Do</span>
              </h3>
              <p className="section_subtitle mx-auto text-muted">
                {props.whatWeDoBlurb}
                {/* All icons were sourced from The Noun Project */}
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <StaticQuery
            query={graphql`
              {
                allMarkdownRemark(
                  sort: { fields: fileAbsolutePath, order: ASC }
                  filter: {
                    frontmatter: { templateKey: { eq: "offering-name" } }
                  }
                ) {
                  nodes {
                    frontmatter {
                      image {
                        childImageSharp {
                          fluid(maxWidth: 150, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      title
                      offeringname
                      offeringdescription
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return data.allMarkdownRemark.nodes.map((val, index) => {
                // console.log(val);
                return (
                  <Offering
                    imgSrc={
                      val.frontmatter.image
                        ? val.frontmatter.image.childImageSharp.fluid
                        : ''
                    }
                    offeringname={val.frontmatter.offeringname}
                    offeringdescription={val.frontmatter.offeringdescription}
                    key={index}
                  />
                );
              });
            }}
          ></StaticQuery>
        </div>
        {/* <div className="row mt-3">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <Link className="btn btn_custom" to="/what-we-do-page">
                More Offerings
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};
