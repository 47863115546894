import React from "react";
import { graphql } from "gatsby";
import ".././styles/global.css";
import HomeSection from "../components/home-section/home-section";
import WhatWeDoSection from "../components/whatWeDo/whatWeDo-section";
import ServiceSection from "../components/services/service-section";
import ClientSection from "../components/clients/client-section";
import TeamSection from "../components/team/team-section";
import ProjectSection from "../components/projects-section/project-section";
import PartnerSection from "../components/partners/partner-section";
import ContactSection from "../components/contact-us/contact-us";
import Layout from "../components/Layout";
import useSiteMetadata from "../components/SiteMetadata";

export default ({ data }) => {
  const {
    whatwedoblurb,
    clientblurb,
    serviceblurb,
    teamblurb,
    projectblurb,
    partnerblurb
  } = data.allMarkdownRemark.nodes[0].frontmatter;
  const { title, description } = useSiteMetadata();

  return (
    <Layout>
      <HomeSection />
      <WhatWeDoSection whatWeDoBlurb={whatwedoblurb} />
      <ServiceSection serviceBlurb={serviceblurb} />
      <ProjectSection projectBlurb={projectblurb} />
      <ClientSection clientBlurb={clientblurb} />
      <PartnerSection partnerBlurb={partnerblurb} />
      <TeamSection teamBlurb={teamblurb} />
      <ContactSection />
    </Layout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "index-page" } } }
    ) {
      nodes {
        frontmatter {
          whatwedoblurb
          serviceblurb
          teamblurb
          clientblurb
          projectblurb
          partnerblurb
        }
      }
    }
  }
`;
